/* eslint-disable no-sequences */
import React, { useState, useContext, useEffect } from "react"
import axios from "axios"
import format from "date-fns/format"
import { AuthContext } from "../../../../Utils/context"
import { excludedEmailDomains } from "../../../../Utils/helpers"

import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  TextField,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core"
import makeStyles from "@material-ui/core/styles/makeStyles"

import AddIcon from "@material-ui/icons/Add"
import ClearIcon from "@material-ui/icons/Clear"

const Api = {
  fetchAllDomainsURI: id =>
    `${process.env.REACT_APP_ORG_URL}/${id}/emaildomains`,

  orgFetchEmailDomains: id =>
    axios.get(Api.fetchAllDomainsURI(id)).then(x => {
      return x.data?.emailDomains || []
    }),

  orgPatchEmailDomains: (id, emailDomains) => {
    return axios.patch(Api.fetchAllDomainsURI(id), { emailDomains })
  },
}

const AddEmailDomainDialog = ({ isOpen, onClose, onSubmit }) => {
  const [inputValue, setInputValue] = useState("")
  const [inputError, setInputError] = useState("")
  const [disableSubmit, setDisableSubmit] = useState(true)
  const domainRegex =
    /^(([A-Za-z0-9]|[A-Za-z0-9][A-Za-z0-9-]{0,61}[A-Za-z0-9])\.){1,}([A-Za-z]{2,6})$/

  const handleInputChange = event => {
    const email = event.target.value.trim().toLowerCase()
    setInputValue(email)
    setDisableSubmit(false)
    if (!email) {
      setInputError("Please enter a valid email domain.")
    } else if (excludedEmailDomains.includes(email)) {
      setInputError("This email domain is not allowed.")
    } else if (!domainRegex.test(email)) {
      setInputError("Please enter a valid email domain.")
    } else {
      setInputError("")
    }
  }

  const handleSubmit = () => {
    onSubmit(inputValue)
    setInputValue("")
  }

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <Box minWidth={350} minHeight={50} padding={2}>
        <DialogTitle id="form-dialog-title">Add Email Domain</DialogTitle>
        <DialogContent>
          <TextField
            name="emailDomain"
            value={inputValue}
            onChange={handleInputChange}
            InputLabelProps={{
              shrink: true,
            }}
            error={!!inputError}
            helperText={inputError}
            fullWidth
            autoFocus
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <Button
            onClick={handleSubmit}
            color="primary"
            variant="contained"
            disabled={inputError || disableSubmit}
          >
            Add Email Domain
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  )
}

const Domains = ({ id }) => {
  const classes = getStyles()
  const { createSuccess, createError, handleSnackbarClose } =
    useContext(AuthContext)

  const [orgDomains, setOrgDomains] = useState(null)
  const [confirmOpen, setConfirmOpen] = useState(false)
  const [emailDomainToRemove, setEmailDomainToRemove] = useState()
  const [loading, setLoading] = useState(false)
  const [isDialogOpen, setIsDialogOpen] = useState(false)

  const handleOpenDialog = () => {
    setIsDialogOpen(true)
  }

  const handleCloseDialog = () => {
    setIsDialogOpen(false)
  }

  const handleDialogSubmit = value => {
    fns.addNewEmailDomain(value)
    handleCloseDialog()
  }

  const fns = {
    hideConfirmDialog: () => setConfirmOpen(false),
    closeSnackbarShowDialog: () => {
      handleSnackbarClose()
      fns.showDialog()
    },

    showConfirmDialog: emailDomain => () => {
      handleSnackbarClose()
      setEmailDomainToRemove(emailDomain)
      setConfirmOpen(true)
    },

    hydrate() {
      Api.orgFetchEmailDomains(id).then(setOrgDomains)
    },

    addNewEmailDomain(emailDomain) {
      setLoading(true)
      Api.orgPatchEmailDomains(id, [
        ...orgDomains.map(x => x.domain),
        emailDomain,
      ])
        .then(res => {
          if (!res) return
          createSuccess("Sucessfully added email domain")
          fns.hydrate()
        })
        .catch(error => {
          const err = error?.response?.data
          let customMessage = "There was an error adding the email domain. "
          customMessage =
            err.code === "DomainInUseException"
              ? customMessage + err.details
              : customMessage

          createError({ customMessage })
        })
        .finally(() => setLoading(false))
    },

    removeEmailDomain: emailDomain => {
      const orgEmailDomains = orgDomains
        .filter(e => e.domain !== emailDomain)
        .map(l => l.domain)

      return () => {
        fns.hideConfirmDialog()
        setLoading(true)
        Api.orgPatchEmailDomains(id, orgEmailDomains)
          .catch(() => setLoading(false))
          .then(_res => {
            createSuccess("Email Domain successfully removed")
            setLoading(false)
            fns.hydrate()
          })
      }
    },
  }

  if (!orgDomains) {
    fns.hydrate()
    return (
      <Paper className={classes.root}>
        <Box>Fetching latest data...</Box>
      </Paper>
    )
  }

  const RemoveEmailDomainConfirmationDialog = () => (
    <Dialog
      open={confirmOpen}
      aria-labelledby="confirm-removal-title"
      aria-describedby="confirm-removal"
    >
      <DialogTitle id="confirm-removal-title">Confirm Removal</DialogTitle>
      <DialogContent>
        <DialogContentText id="confirm-removal">
          Remove Email Domain for this organisation?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={fns.hideConfirmDialog}>cancel</Button>
        <Button
          onClick={fns.removeEmailDomain(emailDomainToRemove)}
          color="primary"
          autoFocus
        >
          Remove
        </Button>
      </DialogActions>
    </Dialog>
  )

  if (orgDomains?.length === 0) {
    return (
      <Paper className={classes.root}>
        <Button
          color="primary"
          variant="contained"
          className={classes.actionBtn}
          onClick={handleOpenDialog}
          startIcon={<AddIcon />}
          size="small"
        >
          Add Email Domain
        </Button>
        <AddEmailDomainDialog
          isOpen={isDialogOpen}
          onClose={handleCloseDialog}
          onSubmit={handleDialogSubmit}
        />
        {loading && (
          <Box className={classes.overlay}>
            <CircularProgress />
          </Box>
        )}
      </Paper>
    )
  }

  const DomainRow = emailDomain => {
    return (
      <TableRow key={emailDomain}>
        <TableCell className={classes.grey}>
          <Typography variant="subtitle2" display="inline">
            {emailDomain}
          </Typography>
        </TableCell>

        <TableCell align="right">
          {/* {!e.mapType !== "PLAN_ID" && ( */}
          {!emailDomain.mapType?.startsWith("bi-creator") && (
            <Button
              variant="outlined"
              size="small"
              onClick={fns.showConfirmDialog(emailDomain)}
              startIcon={<ClearIcon />}
              className={classes.deleteButton}
            >
              Remove
            </Button>
          )}
        </TableCell>
      </TableRow>
    )
  }

  const DomainsTable = () => (
    <Table size="small" className={classes.table}>
      <TableHead>
        <TableRow>
          <TableCell>Domains</TableCell>
          <TableCell align="right">
            <Button
              color="primary"
              variant="contained"
              className={classes.actionBtn}
              onClick={handleOpenDialog}
              startIcon={<AddIcon />}
              disabled={orgDomains?.length === 0}
              size="small"
            >
              Add Email Domain
            </Button>
          </TableCell>
        </TableRow>
      </TableHead>

      <TableBody>{orgDomains?.map(e => e.domain).map(DomainRow)}</TableBody>
    </Table>
  )

  return (
    <Paper className={classes.root}>
      <Grid container spacing={3} justify="space-between">
        <DomainsTable />
      </Grid>

      <RemoveEmailDomainConfirmationDialog />

      <AddEmailDomainDialog
        isOpen={isDialogOpen}
        onClose={handleCloseDialog}
        onSubmit={handleDialogSubmit}
      />
      {loading && (
        <Box className={classes.overlay}>
          <CircularProgress />
        </Box>
      )}
    </Paper>
  )
}

const getStyles = makeStyles(theme => ({
  root: {
    minHeight: 300,
    padding: theme.spacing(2),
    position: "relative",
  },
  overlay: {
    position: "absolute",
    background: "rgba(0,0,0,0.2)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    top: 0,
    left: 0,
    height: "100%",
    width: "100%",
  },
  table: {
    // maxWidth: "50vw",
  },
  code: {
    fontFamily: "Roboto Mono",
    margin: 0,
  },
  actionBtn: {
    minWidth: 140,
  },
  deleteButton: {
    width: 140,
    color: theme.palette.danger.dark,
    borderColor: theme.palette.danger.dark,
  },
  angleIcon: {
    fontSize: 14,
    marginRight: 5,
  },
  grey: {
    color: "rgba(0, 0, 0, 0.54)",
  },
}))

export default Domains
